import { getLanguage } from "@cospex/client/helpers/getLanguage";
import i18n from "i18next";
import { initReactI18next } from "react-i18next";

import de from "./lang/de.json";
import en from "./lang/en.json";
import es from "./lang/es.json";
import fr from "./lang/fr.json";
import it from "./lang/it.json";

const language = ["en", "fr", "de", "es", "it"];

i18n.use(initReactI18next).init({
  resources: {
    en: {
      translation: en,
    },
    fr: {
      translation: fr,
    },
    de: {
      translation: de,
    },
    es: {
      translation: es,
    },
    it: {
      translation: it,
    },
  },
  lng: getLanguage(language),
});

export default i18n;
