import ErrorComponent from "@cospex/client/components/Error";
import Header from "@cospex/client/components/Header";
import Footer from "@cospex/client/converter/components/Footer";
import * as Sentry from "@sentry/react";
import { Outlet } from "react-router-dom";

export default function App() {
  return (
    <Sentry.ErrorBoundary fallback={<ErrorComponent />}>
      <Header
        menu={{
          dashboardHref: "/dashboard/overview",
        }}
      />
      <Outlet />
      <Footer />
    </Sentry.ErrorBoundary>
  );
}
