import {
  ALL_CONVERSION_TARGETS,
  CONVERSION_META,
  EXTENSION_TO_COLOR,
} from "./constants";

export const getAcceptedMimeTypes = () => {
  const mimeTypes = [];
  for (const category in CONVERSION_META) {
    const { mimeTypes: categoryMimeTypes } =
      CONVERSION_META[category as keyof typeof CONVERSION_META];
    mimeTypes.push(...categoryMimeTypes);
  }
  return mimeTypes;
};

export const getConversionTargets = (mimeType: string) => {
  const mainType = mimeType.split("/")[0];
  const allTargets = new Set<string>();

  for (const { mimeTypes, targets } of Object.values(CONVERSION_META)) {
    if (
      mimeTypes.includes(mimeType) ||
      mimeTypes.includes(`${mainType}/*`) ||
      mimeTypes.some((type) => type.startsWith(`${mainType}/`))
    ) {
      targets.forEach((target) => allTargets.add(target));
    }
  }

  return allTargets.size > 0 ? Array.from(allTargets) : ALL_CONVERSION_TARGETS;
};

export const getExtensionColor = (extension: string) =>
  EXTENSION_TO_COLOR[extension as keyof typeof EXTENSION_TO_COLOR] || "black";

type OperationQueryResult = {
  convertFrom: string | undefined;
  convertTo: string | undefined;
  convertFromUppercase: string | undefined;
  convertToUppercase: string | undefined;
  specificConversion: boolean;
  compressionFormat: string | null;
} & (
  | {
      specificConversion: true;
      convertFrom: string;
      convertTo: string;
      convertFromUppercase: string;
      convertToUppercase: string;
    }
  | { specificConversion: false }
);

export const getOperationFromQuery = (): OperationQueryResult => {
  const urlParams = new URLSearchParams(window.location.search);
  const compressionFormat = urlParams.get("compressionFormat");

  const from = urlParams.get("from");
  const to = urlParams.get("to");

  const convertFrom = from || undefined;
  const convertTo = to || undefined;

  const specificConversion =
    convertFrom !== undefined && convertTo !== undefined;

  return {
    convertFrom,
    convertTo,
    convertFromUppercase: convertFrom?.toUpperCase(),
    convertToUppercase: convertTo?.toUpperCase(),
    specificConversion,
    compressionFormat,
  } as OperationQueryResult;
};

export async function getImageMeta(file: File): Promise<ImageMeta> {
  const img = new Image();
  const url = URL.createObjectURL(file);
  img.src = url;
  await img.decode();
  const width = img.width;
  const height = img.height;
  return {
    url,
    width,
    height,
  };
}
